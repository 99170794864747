































import { Mixins, Component } from 'vue-property-decorator';
import FormMixin from '@/app/core/mixins/form.mixin';
import { email, required } from '@/app/core/validation';
import RequestPasswordResetDto from '../dto/request-password-reset.dto';

@Component
export default class RequestPasswordResetForm extends Mixins(FormMixin) {
  protected data: RequestPasswordResetDto = {
    email: '',
  };

  protected rules = {
    email: [required, email],
  };
}
