





















































import { Component, Mixins } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import userService from '../services/user.service';
import RequestPasswordResetDto from '../dto/request-password-reset.dto';
import RequestPasswordResetForm from '../components/request-password-reset-form.vue';

@Component({
  components: {
    RequestPasswordResetForm,
  },
  metaInfo(this: RequestPasswordReset) {
    return { title: this.$t('pageTitle.requestPasswordReset').toString() };
  },
})
export default class RequestPasswordReset extends Mixins(HandlesErrorMixin) {
  private loading = false;
  private showSuccessIcon = false;
  private steps = {
    requestPasswordReset: 0,
    resetPasswordEmailSent: 1,
  };
  private step = this.steps.requestPasswordReset;

  resetPasswordEmailSent() {
    setTimeout(() => {
      this.showSuccessIcon = true;
    }, 300);

    this.step = this.steps.resetPasswordEmailSent;
  }

  async onSubmit(data: RequestPasswordResetDto) {
    this.loading = true;

    try {
      await userService.requestPasswordReset(data);
      this.resetPasswordEmailSent();
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
